import { Box, Typography } from '@mui/material'
import React from 'react'

const Gyckel = () => {
    return (
        <Box>
            <Typography variant="h3">När är nästa gyckel?</Typography>
            <Typography variant="body1">På nästa sittning 😎</Typography>
        </Box>
    )
}

export default Gyckel
